import { useHistory } from 'react-router';
import { constants } from '../../constants';
import { dateTimeUtils } from '../../utils';
import { castArray } from 'lodash';
import IconSVG from '../../styles/svg-icons';
import { OnHoverTooltip } from '../common';

interface DealsHeaderBaseProps {
    title: string;
    dateUpdated: Date;
    renderTitleSuffix?: () => React.ReactNode;
    renderLinks?: () => React.ReactNode | React.ReactNode[];
    editButtonUrl?: string;
    editTooltip?: string;
    isEditDisabled?: boolean;
    onDeleteClick?: () => void;
}

export const DealsHeaderBase = ({
    title,
    dateUpdated,
    renderTitleSuffix,
    renderLinks,
    editButtonUrl,
    editTooltip,
    isEditDisabled,
    onDeleteClick,
}: DealsHeaderBaseProps) => {
    const history = useHistory();
    const dateUpdatedEst = dateTimeUtils.changeDateTimeZone(
        dateUpdated,
        constants.estTimezone,
        false
    );

    return (
        <div className="sub-header sub-header-deals">
            <div className="sub-header-row type02">
                <div className="flex-row">
                    <h1>{title}</h1>
                    {renderTitleSuffix && (
                        <>
                            <div className="vertical-divider" />
                            {renderTitleSuffix()}
                        </>
                    )}
                    {(editButtonUrl || onDeleteClick) && (
                        <div className="flex-item-right flex-row">
                            {onDeleteClick &&
                                <div className="flex-item-right flex-row">
                                    <button className="btn-link btn-danger" onClick={onDeleteClick}>
                                        <IconSVG name="basket" width={16} height={16} />
                                        Delete
                                    </button>
                                </div>
                            }
                            {editButtonUrl &&
                                <OnHoverTooltip overlay={editTooltip}>
                                    <button disabled={isEditDisabled} className="btn btn-ghost btn-sm" onClick={() => history.push(editButtonUrl)}>
                                        Edit
                                    </button>
                                </OnHoverTooltip>
                            }
                        </div>
                    )}
                </div>
                <div className="flex-row">
                    <div className="header-bwic-rules">
                        <OnHoverTooltip
                            overlay={`Deal Last Updated: ${dateUpdatedEst.format(constants.dateTimeFormat)} EST`}
                            wrapperClass="text-sm"
                        >
                            {dateUpdatedEst.format(constants.dateFormat)}
                        </OnHoverTooltip>
                    </div>
                    {renderLinks && castArray(renderLinks()).map((el, index) => (
                        <div key={index} className="header-bwic-rules">
                            {el}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
